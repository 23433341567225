


















































import { Component, Vue, PropSync, Prop } from "vue-property-decorator";
import {
  DxHtmlEditor,
  DxToolbar,
  DxMediaResizing,
  DxItem,
} from "devextreme-vue/html-editor";
@Component({
  components: { DxHtmlEditor, DxToolbar, DxItem, DxMediaResizing },
})
export default class HTMLEditor extends Vue {
  @Prop({ default: "100%" }) maxheight!: string;
  @PropSync("markup", { type: String }) syncedmarkup!: string;
  public sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
  public fontValues = [
    "Arial",
    "Courier New",
    "Georgia",
    "Impact",
    "Lucida Console",
    "Tahoma",
    "Times New Roman",
    "Verdana",
  ];
  public headerValues = [false, 1, 2, 3, 4, 5];
  public enabled = {
    enabled: true,
  };
}
